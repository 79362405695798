import React, { useEffect, useState } from 'react'
import FaqAccordion from '../components/Faq/FaqAccordion'
import Blog from '../components/Home/Blog'
import Layout from '../components/Layout';
import allApiData from '../api.js';
import Hero from '../components/Mobileapp/hero'
import Seo from '../components/Seo';
import Seoheadata from '../siteScriptData';


function Faq() {
    const [allBlogData, setAllBlogData] = useState([]);
    const [pageNum, setPageNum] = useState(1);

    allApiData.removeanimation();
    useEffect(() => {
        allApiData.aosinit()
    }, []);

    useEffect(() => {
        allApiData.allblogData(pageNum).then((response) => {
            setAllBlogData(response)
        });
    }, [pageNum])
    return (
        <Layout>
            <Seo SeoData={Seoheadata.faqsPageSeoData}></Seo>
            <div className='faq-page' >
                <Hero title=" Frequently Asked Questions"
                    def="If you have questions about our services, experiences and approach; here are their answers. We are happy to answer any other questions you may have. "
                    cta='Get a Quote'
                >
                    <div className="hero-img hide-animation-768">
                        <img src="../assets/img/faq/hero_img/faqs-about-vasundhara.svg" className="wh-auto z-index img-fluid" alt="faqs-about-vasundhara"
                            width="743" height="443"
                        />
                        <img src="../assets/img/faq/hero_img/1.svg" className=" f-img-one img-fluid" alt="faqs-about-vasundhara-one" />
                        <img src="../assets/img/faq/hero_img/2.svg" className=" f-img-two img-fluid" alt="faqs-about-vasundhara-two" />
                        <img src="../assets/img/faq/hero_img/3.svg" className=" f-img-three img-fluid" alt="faqs-about-vasundhara-three" />
                        <img src="../assets/img/faq/hero_img/4.svg" className=" f-img-four img-fluid" alt="faqs-about-vasundhara-four" />
                    </div >
                    <div className="show-main-img-768 ">
                        <img src="../assets/img/faq/hero_img/faqs-about-vasundhara-mobile.svg" className="wh-auto img-fluid mt-4" alt="faqs-about-vasundhara-mobile"
                        width="680" height="406" />
                    </div>
                </Hero >
                <FaqAccordion />
                <div className="blog-section ">
                    <Blog heding="Latest Blogs" blogdata={allBlogData.data} limitBlog={3} />
                </div >
            </div >
        </Layout >
    )
}

export default Faq