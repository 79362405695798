import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion';
import allApiData from '../../api';
import allData from '../../allData';


function FaqAccordion() {

    const [active, setActive] = useState(1);

    const [ques, setQue] = useState([]);
    const [currentTab, setCurrentTab] = useState('All');
    let tabButton = [
        'All',
        'About Services',
        'Application Development',
        'Game Development',
        'Website Development',
        'Billing',
    ]
    useEffect(() => {
        allApiData.allFaqs(currentTab).then((faq) => {
            setQue(faq.data)
        })
    }, [currentTab]);

    let activeTab = (e) => {
        setCurrentTab(e.target.id)
    }

    return (

        <div className='faqaccordion ' data-aos="zoom-in" >
            <Container>
                <ul className='ps-0 justify-content-lg-center justify-content-start'>
                    {
                        tabButton && tabButton.map((tab, i) => {
                            return (
                                <li key={i} className={`${currentTab === tab ? " active-color" : ""}`} id={tab} onClick={(e) => { activeTab(e) }}>
                                    {tab}
                                </li>
                            )
                        })
                    }
                </ul>


                <div className="Faq mt-lg-5 mt-3    ">

                    <Row className="justify-content-center">
                        <Col lg={12}>
                            <Accordion defaultActiveKey="0">
                                {
                                    ques && ques.map((data, i) => {
                                        function createMarkup() {
                                            return { __html: data.answer };
                                        }

                                        function MyComponent() {
                                            return <div dangerouslySetInnerHTML={createMarkup()} />;
                                        }
                                        return (
                                            <Accordion.Item eventKey={i} key={i} data-aos="zoom-in">
                                                <Accordion.Header>
                                                    <h5>{data.question}</h5>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p><MyComponent /></p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    })
                                }
                            </Accordion>
                        </Col>
                    </Row>

                </div>
            </Container>
        </div>

    )
}

export default FaqAccordion 